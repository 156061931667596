<template>
  <div>
    <!-- <h2>Status</h2> -->
    <!-- <b-row v-if="reportData.statistics"> -->
    <b-row v-if="false">
      <b-col>
        <statistic-card-vertical
          color="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.Hired"
          statistic-title="Hired"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="ListIcon"
          :statistic="reportData.statistics.Shortlisted"
          statistic-title="Shortlisted "
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="XIcon"
          :statistic="reportData.statistics.Rejected"
          statistic-title="Unsuccessful"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="warning"
          icon="ClockIcon"
          :statistic="reportData.statistics['Kept In View']"
          statistic-title="Pending"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="danger"
          icon="AlertTriangleIcon"
          :statistic="reportData.statistics.Unknown"
          statistic-title="Unknown"
        />
      </b-col>
    </b-row>
    <hr>

    <h3>Job Seeker</h3>

    <b-row v-if="false">
      <b-col>
        <statistic-card-horizontal
          icon="BriefcaseIcon"
          :statistic="reportData.statistics.total_jobs"
          statistic-title="Total Jobs"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.total_applicants"
          statistic-title="Total Applicants"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="reportData.statistics.total_applications"
          statistic-title="Scheduled Interview"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          variant="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.all_interviews_completed"
          statistic-title="Interview Completed"
        />
      </b-col>
    </b-row>

    <b-row>
      <!-- <b-col
        cols="12" xl="6" md="12"
      >
        <number-of-applicant-chart
          :report-data="reportData"
          :which-page="'summary'"
        />
      </b-col> -->

      <!-- <b-col
        cols="12" xl="6" md="6"
      >
        <popular-company :data="reportData.popular_companies" />
      </b-col> -->

      <b-col
        cols="12" xl="6" md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_users_account"
          statistic-title="Number of Job Seekers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <number-of-applicant-chart
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="job_seeker"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12" xl="6" md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <age-demographic-chart
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Age demographic"
            section="job_seeker"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12" xl="4" md="6"
      >
        <gender-chart :report-data="reportData" section="job_seeker"/>
      </b-col>

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <citizenship-chart :report-data="reportData" />
      </b-col> -->

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <qualification-level-list :report-data="reportData" />
      </b-col> -->

      <b-col
        cols="12" xl="4" md="6"
      >
        <employment-status-chart :report-data="reportData" section="job_seeker"/>
      </b-col>

      <b-col
        cols="12" xl="4" md="6"
      >
        <qualification-level-list
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        v-if="false"
        cols="12" xl="4" md="6"
      >
        <job-and-application-list
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        v-if="false"
        cols="12" xl="6" md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Job Applications - 30 days"
            section="job_seeker"
            data-name="job_applications_chart"
          />
        </b-col>
      </b-col>

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <interview-type-chart :report-data="reportData" />
      </b-col> -->
    </b-row>


    <h3>Employer</h3>

    <b-row>
      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <gender-chart :report-data="reportData" section="employer"/>
      </b-col>
      <b-col
        cols="12" xl="3" md="6"
      >
        <employment-status-chart :report-data="reportData" section="employer"/>
      </b-col> -->

      <b-col
        cols="12" xl="6" md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_employers_account"
          statistic-title="Number of Employers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <number-of-applicant-chart
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="employer"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12" xl="6" md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Active Job - 30 days"
            section="employer"
            data-name="active_job_chart"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12" xl="6" md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12" xl="12" md="12"
        >
          <MultiDataChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Jobs & Applications - 30 days"
            section="employer"
            data-name="multiline_application_and_job_chart"
          />
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import CitizenshipChart from './CitizenshipChart.vue'
import EmploymentStatusChart from './EmploymentStatusChart.vue'
import GenderChart from './GenderChart.vue'
import InterviewTypeChart from './InterviewTypeChart.vue'
import NumberOfApplicantChart from './NumberOfApplicantChart.vue'
import AgeDemographicChart from './AgeDemographicChart.vue'
import PopularCompany from './PopularCompany.vue'
import QualificationLevelList from './QualificationLevelList.vue'
import JobAndApplicationList from './JobAndApplicationList.vue'

import ChartComponent from './ChartComponent.vue'
import MultiDataChartComponent from './MultiDataChartComponent.vue'

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardVertical,

    CitizenshipChart,
    EmploymentStatusChart,
    GenderChart,
    InterviewTypeChart,
    AgeDemographicChart,
    NumberOfApplicantChart,
    PopularCompany,
    QualificationLevelList,
    JobAndApplicationList,
    ChartComponent,
    MultiDataChartComponent,
  },
  props: ['reportData'],
  data() {
    return {}
  },
}
</script>

<style></style>
