<template>
  <b-card
    no-body
    style="height: 60vh"
  >
    <b-card-header>
      <b-card-title>Types of Interview</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="300"
          class="my-1"
          :options="interviewType.chartOptions"
          :series="interviewType.series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === chartInfo.length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :style="`color: ${data.color}`"
            />
            <span class="font-weight-bold text-capitalize ml-75">{{
              data.label
            }}</span>
          </div>

          <span>{{ data.value }}</span>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: ['reportData'],
  data() {
    return {
      isLoading: false,
      chartInfo: [
        {
          label: 'In Person',
          value: 0,
          color: $themeColors.info,
        },
        {
          label: 'Virtual Interview',
          value: 0,
          color: $themeColors.warning,
        },
        {
          label: 'Virtual Speed',
          value: 0,
          color: $themeColors.dark,
        },
      ],
      interviewType: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          colors: [],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      },
    }
  },

  watch: {
    reportData(newVal, oldVal) {
      this.updateData(newVal)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      const chartData = newVal.statistics.charts.event_type
      this.chartInfo = [
        {
          label: 'In Person',
          value: chartData.physical,
          color: $themeColors.info,
        },
        {
          label: 'Virtual Interview',
          value: chartData.virtual,
          color: $themeColors.warning,
        },
        {
          label: 'Virtual Speed',
          value: chartData.speed_interview,
          color: $themeColors.dark,
        },
      ]

      this.interviewType = {
        series: [
          chartData.physical,
          chartData.virtual,
          chartData.speed_interview,
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['In Person', 'Virtual Interview', 'Virtual Speed'],
          colors: [$themeColors.info, $themeColors.warning, $themeColors.dark],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
    },
  },
}
</script>
